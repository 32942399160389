import { Component, OnInit } from '@angular/core';
import { WorkHistoryItem } from './work-history-item/work-history-item.model';
import { WorkHistoryService } from './work-history.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {

  workHistoryItems: WorkHistoryItem[];

  educationItems: WorkHistoryItem[];

  get yearsInWebDevelopment() {
    return new Date().getFullYear() - 2008;
  }

  constructor(private workHistoryService: WorkHistoryService) {

  }

  ngOnInit() {
    this.workHistoryItems = this.workHistoryService.getHistory();
    this.educationItems = this.workHistoryService.getEducation();
  }
}
