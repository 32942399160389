<form name="contactForm" fxLayout="column" fxLayoutGap="5px" #form=ngForm (ngSubmit)="onSubmit()" >
    <mat-form-field>
      <input name="emailAddress" matInput [(ngModel)]="contact.email" type="email" placeholder="Your Email Address" required email #email=ngModel />
      <mat-error *ngIf="!email.valid">
        Please enter a valid email
      </mat-error>
    </mat-form-field>
  <mat-form-field>
    <textarea name="message" matInput [(ngModel)]="contact.message" placeholder="Your Message" rows="2" required #message=ngModel maxlength="1000"></textarea>
    <mat-error *ngIf="!message.valid">
      Please enter a message.
    </mat-error>
  </mat-form-field>
  <mat-error *ngIf="submitError">
    There was an error sending the message. Please try again later.
  </mat-error>
  <p *ngIf="submitSuccess">Thanks for contacting me!</p>
  <button type="submit" mat-raised-button class="mat-primary" [disabled]="!form.valid || submitted">Send Message</button>
</form>
