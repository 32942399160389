<div class="work-history-item">
  <img *ngIf="item.logo" class="work-history-logo" [attr.alt]="item.logo.altText" [attr.src]="item.logo.path"/>
  <h3 class="work-history-title mat-subheading-3" fxLayout="row" fxLayout.lt-md="column">
    <span fxFlex>{{item.name}}</span>
    <span fxFlex>{{item.title}}</span>
    <span fxFlex>{{item.dateDescription}}</span>
  </h3>
  <ul class="work-history-item-detail-list">
    <li *ngFor="let detail of item.items">{{detail.description}}</li>
  </ul>
</div>
