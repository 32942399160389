import { Component, Input, OnInit } from '@angular/core';
import { WorkHistoryItem } from './work-history-item.model';

@Component({
  selector: 'app-work-history-item',
  templateUrl: './work-history-item.component.html',
  styleUrls: ['./work-history-item.component.sass']
})
export class WorkHistoryItemComponent implements OnInit {
  item: WorkHistoryItem;

  constructor() { }

  @Input()
  get workHistoryItem(): WorkHistoryItem {
    return this.item;
  }

  set workHistoryItem(value: WorkHistoryItem) {
    this.item = value;
  }

  ngOnInit() {
  }

}
