import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppInitProvider, init } from './app.init';
import { ContactComponent } from './contact/contact.component';
import { WorkHistoryItemComponent } from './work-history-item/work-history-item.component';
import { WorkHistoryService } from './work-history.service';

@NgModule({
  declarations: [
    AppComponent,
    WorkHistoryItemComponent,
    ContactComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatFormFieldModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [
    WorkHistoryService,
    AppInitProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
