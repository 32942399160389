import { Injectable } from '@angular/core';
import { WorkHistoryItem } from './work-history-item/work-history-item.model';

@Injectable({
  providedIn: 'root'
})
export class WorkHistoryService {

  constructor() {
  }

  getHistory(): WorkHistoryItem[] {
    return [
      {
        name: 'Elevate Credit',
        dateDescription: 'April 2019 - Current',
        title: 'Team Lead',
        logo: {
          altText: 'Elevate Credit Logo',
          path: 'assets/elevate.png'
        },
        items: [
          {
            description: 'As a Team Lead, I lead a group of developers to ensure that the team meets objectives and deadlines. ' +
              'This includes mentoring and coaching as well as ensuring that tasks are assigned and worked in a timely manner and roadblocks cleared.' +
              'I also work to ensure that outside issues that crop up during a sprint are triaged, prioritized, and addressed.'
          }, {
            description: 'Led a team that leveraged .NET, Angular, and MuleSoft API Management to build one the first cross-product microservices in the organization. ' +
              'From the outset of the project, we anticipated needs and built our application in a highly configurable and extendable fashion.' +
              'We used Mediatr to build a truly extensible framework to run eligibility rules and loan modification programs that supported multiple products. ' +
              'We leveraged Entity Framework to connect to dynamic data sources based on both environment and product.'
          }, {
            description: 'Worked on the AngularJS front-end site to integrate a third-party feature for letting customer service agents co-browse with site visitors.'
          },
        ]
      },
      {
        name: 'IHS Markit',
        dateDescription: 'August 2013 – April 2019',
        title: 'Principal Software Developer',
        logo: {
          altText: 'IHS Markit Logo',
          path: 'assets/IHSMarkit_logo.png'
        },
        items: [
          {
            description: 'Leveraged .NET MVC 4, WebAPI, jQuery, Durandal, RequireJS, ' +
              'and Bootstrap to create a proof-of-concept for a new product initiative that provided precedence for using OpenF2, ' +
              'a framework for aggregating and managing cross-domain widgets.'
          },
          {
            description: 'I was instrumental in an initiative to migrate an existing Silverlight ' +
              'application to an HTML5/JavaScript. We were able to convert a large portion of the ' +
              'C# code to Typescript and then use RequireJS for dependency management and Knockout for binding.'
          },
          {
            description: 'Started a greenfield project where we leveraged AngularJS, Gulp, RequireJS and ' +
              'ASP.NET MVC, which has since changed to leverage Webpack instead of Gulp and RequireJS, ' +
              'removed the MVC dependency, and started upgrading from AngularJS to Angular (currently version 5), ' +
              'upgrading one feature at a time as business objectives allow.'
          },
          {
            description: 'Involved in pioneering new data analytics and business intelligence product involving ' +
              'extracting data from one product into AWS S3 buckets and leveraging AWS Glue for ETL and data-warehousing. ' +
              'Eventually processed data was fed into AWS Quicksight for analysis and visualization. '
          },
          {
            description: 'Spearheaded project to convert existing WPF application to web front-end and backend built using ' +
              'microservices designed to be hosted in either local or cloud infrastructure.'
          },
          {description: 'Leveraged Angular CLI to build NPM modules to share functionality across several web applications.'}
        ]
      },
      {
        name: 'DR Horton (contract)',
        dateDescription: 'June 2013 – August 2013',
        title: 'Software Developer',
        logo: {
          altText: 'DR Horton Logo',
          path: 'assets/Dr-Horton-Logo.jpg'
        },
        items: [
          {
            description: 'Work on a highly collaborative team to implement a sales tool for ' +
              'in-field agents to quickly and accurately close new homes.'
          },
          {description: 'Leveraged MVC, WCF, WIF, Kendo UI, and JQuery to implement a fluid single page app.'},
        ]
      },
      {
        name: 'EF Johnson Technologies',
        dateDescription: 'April 2012 – June 2013',
        title: 'Software Developer',
        logo: {
          altText: 'EF Johnson Technologies Logo',
          path: 'assets/EFJlogoREV.jpg'
        },
        items: [
          {
            description: 'Leveraged .NET MVC 4, WebAPI, Entity Framework, JQuery, Knockout.js, underscore.js, ' +
              'Typescript, MS-SQL, SSIS, SSRS, and Oracle to deliver well designed and implemented solutions ' +
              'to a wide variety of problems from manufacturing, sales, operations, and HR. '
          },
          {
            description: 'Designed, developed, and maintained a new modular membership architecture to allow ' +
              'multiple types of users into our system, including internal, external, and dealers.'
          },
          {
            description: 'Designed, developed, and maintained a forms framework to handle multiple types of ' +
              'forms and their respective approval flow.'
          },
        ]
      },
      {
        name: 'Match.com',
        dateDescription: 'May 2008 – April 2012',
        title: 'Application Developer',
        logo: {
          altText: 'Match.com Logo',
          path: 'assets/match-com-logo.png'
        },
        items: [
          {
            description: 'Worked in a high profile, high visibility environment on the Domestic Match.com Product, ' +
              'the leading dating site in the US. Developed new features as well as maintained ' +
              'existing code to achieve product initiatives.'
          },
          {description: 'Leveraged MVC to implement an API endpoint for Yahoo personals during the acquisition phase in 2010.'},
          {description: 'Implemented Login and Registration integration with Facebook using the Facebook JavaScript SDK.'},
          {description: 'Designed and implemented SEO search term tracking to assist in SEO initiatives.'},
          {
            description: 'Assisted with putting existing code under unit test and writing unit/integration tests on new code. ' +
              'We Leveraged Unity as our IoC container, NUnit as the test harness, and Moq as our mocking framework.'
          },
        ]
      }
    ];
  }

  getEducation(): WorkHistoryItem[] {
    return [
      {
        logo: {
          path: 'assets/UTA_Logo.png',
          altText: 'University of Texas at Arlington'
        },
        dateDescription: '2008',
        items: [{ description: 'Bachelor of Science in Information Systems' } ],
        name: 'University of Texas at Arlington',
        title: ''
      }
    ];
  }
}
