import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ContactService } from './contact.service';
import { ContactConfiguration } from './contact/contact-configuration.model';

export const AppInitProvider = { provide: APP_INITIALIZER, useFactory: init, deps: [HttpClient, ContactService], multi: true };

interface ConfigurationResponse {
  contact: ContactConfiguration;
}

export function init(http: HttpClient, contactService: ContactService) {

  function initContactService(config: ConfigurationResponse) {
    contactService.initialize(config.contact);
  }

  return () => {
    return fetch('/assets/config/config.json')
      .then((response: Response) => {
        return response.json();
      })
      .then((config: ConfigurationResponse) => {
        initContactService(config);
      });
  };
}
