import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContactConfiguration } from './contact/contact-configuration.model';
import { ContactModel } from './contact/contact.model';
import { ContactResponseModel } from './contact/contactResponse.model';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  private config: ContactConfiguration;

  constructor(private http: HttpClient) { }

  initialize(config: ContactConfiguration) {
    this.config = config;
  }

  sendContact(contact: ContactModel): Observable<ContactResponseModel> {

    this.ensusreConfig();

    return this.http.put(`${this.config.api}contact/`, {
      contact_email: contact.email,
      contact_message: contact.message
    });
  }

  private ensusreConfig() {
    if(!this.config) {
      throw new Error('Contact Service has not been initialized!');
    }
  }
}
