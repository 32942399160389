import { Component, OnInit } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { ContactService } from '../contact.service';
import { ContactModel } from './contact.model';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.sass']
})
export class ContactComponent implements OnInit {

  constructor(private contactService: ContactService) { }

  contact: ContactModel = { email: '', message: '' };

  submitted = false;

  submitError = false;

  submitSuccess = false;

  onSubmit() {
    this.submitted = true;
    this.submitError = false;
    this.submitSuccess = false;
    this.contactService.sendContact(this.contact).pipe(
      catchError(this.onContactError.bind(this))
    ).subscribe(this.onContactSubmitted.bind(this));
  }

  onContactSubmitted() {
    this.submitSuccess = true;
    console.log('Contact Submitted');
  }

  onContactError() {
    this.submitted = false;
    this.submitError = true;
  }

  ngOnInit() {
  }

}
