<div class="banner" fxLayout="column">
  <div fxFlex fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-display-4">Daniel Phelps</h1>
    <h1 class="mat-display-3" fxHide.lt-md=""> Web Developer</h1>
  </div>
  <div class="scroll-hint no-print">
    <mat-icon>expand_more</mat-icon>
  </div>
</div>

<section>
  <img class="section-image" alt="Daniel Phelps Portrait" src="assets/IMG_5010.JPG">
  <p>
    I've been interested in computers since I was a kid. Now I tell them what to do! I've been doing web development for about {{yearsInWebDevelopment}} years and I love it.
    I've worked on projects from a huge public-facing social platform to small in-house applications with only a few users at time.
    I've been on teams that spanned continents to having just one other developer sitting at a desk next to mine.
  </p>
  <p>
    I love working in a collaborative environment with smart people and learning everything I can from them.
    There's nothing like getting a few people together to see the different ideas we each come up with, then combine them to make something really great and solve problems.
  </p>
  <p>
    I really enjoy getting my hands dirty. I learn by digging in and playing around with new technologies.
    I can read all the books and blogs about new tech, but it stays abstract until I get a chance to build something.
  </p>
</section>
<mat-divider class="no-print"></mat-divider>
<section class="no-print">
  <h1 class="mat-title">Virtuoso <small>(ISTP-A)</small></h1>
  <img class="section-image" alt="virtuoso image" src="assets/explorers_Virtuoso_ISTP_introduction.svg" />
  <blockquote>&quot;Virtuosos love to explore with their hands and their eyes, touching and examining the world around them with cool rationalism and spirited curiosity. People with this personality type are natural Makers, moving from project to project, building the useful and the superfluous for the fun of it, and learning from their environment as they go. Often mechanics and engineers, Virtuosos find no greater joy than in getting their hands dirty pulling things apart and putting them back together, just a little bit better than they were before.&quot;</blockquote>
  <a class="personalities-link" href="https://www.16personalities.com/istp-personality">More about Virtuosos at 16personalities.com</a>
</section>
<mat-divider class="no-print"></mat-divider>
<section>
  <h1 class="mat-title">Where I've Worked</h1>
  <app-work-history-item *ngFor="let item of workHistoryItems" [workHistoryItem]="item"></app-work-history-item>
</section>
<mat-divider class="no-print"></mat-divider>
<section>
  <h1 class="mat-title">My Education</h1>
  <app-work-history-item *ngFor="let item of educationItems" [workHistoryItem]="item"></app-work-history-item>
</section>
<section>
  <h1 class="mat-title">Technologies</h1>
  <div fxLayout="row wrap" fxLayout.lt-md="column" >
    <div fxFlex="50" fxFlex.lt-md="">
      <h2>Languages</h2>
      <p>I'm <i>Proficient</i> with Typescript, JavaScript, C#, HTML, CSS, SASS, LESS, SQL.</p>
      <p>I'm <i>Familiar</i> with Python, Ruby, Java, Swift.</p>
    </div>
    <div fxFlex="50" fxFlex.lt-md="">
      <h2>Application Frameworks</h2>
      <p>I'm <i>Proficient</i> with Angular, Bootstrap, Material, Node, Express, ASP.NET MVC and WebAPI.</p>
      <p>I'm <i>Familiar</i> with React, Vue, Ruby on Rails, IOS.</p>
    </div>
    <div fxFlex="50" fxFlex.lt-md="">
      <h2>Build, Test, and Deploy Tools</h2>
      <p>I'm <i>Proficient</i> with Protractor, Jasmine, Webpack, RequireJS, Gulp, Grunt.</p>
      <p>I'm <i>Familiar</i> with Octopus Deploy, Azure Dev Ops Build and Deploy, Team City.</p>
    </div>
    <div fxFlex="50" fxFlex.lt-md="">
      <h2>Databases</h2>
      <p>I'm <i>Proficient</i> with MS-SQL, Azure SQL.</p>
      <p>I'm <i>Familiar</i> with MySQL.</p>
    </div>
  </div>
</section>
<mat-divider class="no-print"></mat-divider>
<section class="no-print">
  <h1 class="mat-title">Send Me A Message</h1>
  <app-contact></app-contact>
</section>
